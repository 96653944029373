export default {
  size: {
    smaller: '10px',
    small: '12px',
    default: '14px',
    medium: '18px',
    big: '24px',
    biggest: '48px',
  }
}
